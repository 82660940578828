#body {
    padding: 50px;
    font-family: Arial, sans-serif;
}

#table1 tr, td{
    width: 50%;
    text-align: left;
    padding-right: 30px;
}

#dichvuText{
    font-size: 60px;
    text-align: left;
}

#table1 p {
    text-align: left;
    letter-spacing: 2px;
    font-size: 18px;
}

#table2 tr, td{
    text-align: center;
    padding-top: 15px;
    padding-bottom: 55px;
    padding-right: 30px;
    vertical-align: top;
}

#body img {
    width: 360px;
    height: 360px;
}


#topic {
    font-size: 25px;
    padding-top: 15px;
}