#imageHeader {
  width: 100%;
  height: 100%;
}

#BatDauBtn {
  background-color: navy;
  font-size: 18px;
  color: azure;
  width: 180px;
  height: 40px;
  border: none;
}